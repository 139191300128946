import styled from 'styled-components';

const NavStyles = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;

  li {
    padding-bottom: 5px;
  }
  
  .Nav__option {
    color: ${props => props.theme.black};
    font-size: 13px;
    font-weight: 600;
    line-height: 200%;
    padding-left: 30px;

    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }

  .Subnav {
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  .Subnav__btn:hover {
    cursor: pointer;
  }

  .Subnav__content {
    background-color: ${props => props.theme.white};
    opacity: 0.95;
    display: none;
    list-style: none;
    padding: 10px 20px;
    position: absolute;
    text-align: left;
    transition: 0.3s ease-in-out;
    z-index: 1;

    li {
      padding: 10px;
    }
  }

  .Subnav:hover .Subnav__content {
    display: block;
  }

  .Nav__btn--mobile {
    &:hover {
      cursor: pointer;
    }

    @media only screen and (min-width: 1199px) {
      display: none;
    }
  }

  .Nav__container--mobile {
    background: ${props => props.theme.white};
    height: 100%;
    left: 0;
    opacity: 0.95;
    padding-left: 0;
    position: fixed;
    text-decoration: none;
    top: 100px;
    width: 100%;
    z-index: 1000;

    li, button {
      color: ${props => props.theme.black};
      font-size: 16px;
      font-weight: 800; 
      line-height: 25px;
      list-style: none;
      padding-left: 20px;
    }
  }

  /* reset button styles */
  button {
    border: 0;
    background: none;
  }

  button:focus {
    outline: none;
  }
`;

export default NavStyles;

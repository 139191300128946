/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from 'prop-types';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import Content from './styles/content';
import Footer from './footer';
import Header from './header';

const theme = {
  // colors
  black: '#000000',
  darkGray: '#424242',
  gray: '#F6F6F6',
  midGray: '#9E9E9E',
  orange: '#FF671D',
  lightOrange: '#FE886C',
  white: '#FFFFFF',
};

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }
  html,
  body {
    color: ${props => props.theme.black};
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    width: 100%;
  }
  a {
    color: ${props => props.theme.black};
    font-size: inherit;
    transition: 0.2s ease-in-out;
    &:hover {
      color: ${props => props.theme.orange};
    }
    &.muted {
      color: #8F94A4;
      text-decoration: none;
      &:hover {
        color: ${props => props.theme.orange};
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.black};
    letter-spacing: 0.25px;
    margin: 0;
  }
  p {
    color: ${props => props.theme.black};
    margin: 0;
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <Content>
        <GlobalStyle />
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
        </div>
        <Footer />
      </Content>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

import React, { Component } from 'react';
import styled from 'styled-components';
import { slide as Menu } from 'react-burger-menu';

import NavStyles from './styles/nav-styles';

const NewNav = styled.div`
    a {
        text-decoration: none;
        font-weight: 800;
        font-size: 18px;
        line-height: 280%;
    }

    /* button:focus {outline:0;} */

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        width: 36px;
        height: 30px;
        left: initial;
        top: 36px;
        right: 36px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: ${props => props.theme.black};
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        background: ${props => props.theme.black};
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: ${props => props.theme.black};
    }

    /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
    .bm-menu-wrap {
        position: fixed;
        height: 100%;
        top: 0;
    }

    /* General sidebar styles */
    .bm-menu {
        background: ${props => props.theme.white};
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: ${props => props.theme.black};
        padding: 0.8em;
    }

    /* Individual item */
    .bm-item {
        display: inline-block;

        :focus {
            outline: none;
        }
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
`;

const NavOptions = [
    {
        id: 2,
        name: 'Membership Benefits',
        path: '/member-benefits',
    },
    {
        id: 4,
        name: 'Member Info Update',
        path: '/member-info-update',
    },
    {
        id: 5,
        name: 'Join Today',
        path: '/join-now',
    },
    {
        id: 6,
        name: 'Survey Center',
        path: '/survey-center',
    },
    {
        id: 8,
        name: 'Vendors',
        path: '/vendor-directory',
    },
    {
        id: 10,
        name: 'History',
        path: '/history',
    },
    {
        id: 11,
        name: 'Board & Committee',
        path: '/board-committee',
    },
    {
        id: 12,
        name: 'Contact',
        path: '/contact-us',
    },
    {
        id: 13,
        name: 'Links of Interest',
        path: '/links-of-interest',
    },
];

class Nav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // aboutUsSubMenu: false,
            isNavOpen: false,
            // membershipSubMenu: false,
        };
    }

    toggleMobileNav = () => {
        const { isNavOpen } = this.state;
        this.setState({ isNavOpen: !isNavOpen });
    };

    renderNavOptions = () => {
        NavOptions.map(option => (
            <a id={option.id} className="menu-item" href={option.path}>
                {option.name}
            </a>
        ));
    };

    render() {
        const { isNavOpen } = this.state;

        return (
            <NavStyles>
                <NewNav>
                    <Menu
                        burgerButtonClassName="right"
                        right
                        isOpen={isNavOpen}
                    >
                        {NavOptions.map(option => (
                            <a
                                id={option.id}
                                className="menu-item"
                                href={option.path}
                            >
                                {option.name}
                            </a>
                        ))}
                    </Menu>
                </NewNav>
            </NavStyles>
        );
    }
}

export default Nav;

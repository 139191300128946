import { Link } from 'gatsby';
// import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Nav from './new-nav';

const Wrapper = styled.div`
  display: flex; 
  flex-direction: row;
  padding: 30px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 350px) {
    padding: 20px;
  }
`;

const Header = () => (
  <header>
    <Wrapper>
      <Link
        to="/"
      >
        <svg width="226" height="60" viewBox="0 0 226 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M215.048 13.0766C206.466 3.89837 195.821 1.36499 195.821 1.36499L190.507 23.483C191.795 23.3807 193.117 23.8328 194.098 24.882C195.889 26.8012 195.889 29.9146 194.098 31.8338C192.537 33.5057 190.123 33.7189 188.34 32.4736L185.167 45.6949L226 33.6592C226 33.6592 223.629 22.2633 215.048 13.0766Z" fill="#F26725" />
          <path d="M0 2.38867H14.3302V44.6629H0V2.38867Z" fill="black" />
          <path d="M20.3012 23.287C20.3012 19.9007 20.8983 16.847 22.0925 14.1174C23.2867 11.3878 24.933 9.06767 27.0142 7.15697C29.1041 5.24627 31.5778 3.7706 34.4523 2.73848C37.3184 1.70636 40.4233 1.18604 43.767 1.18604C47.1107 1.18604 50.2156 1.70636 53.0817 2.73848C55.9477 3.7706 58.4384 5.24627 60.5453 7.15697C62.6522 9.06767 64.307 11.3878 65.5012 14.1174C66.6954 16.847 67.2925 19.9007 67.2925 23.287C67.2925 26.6734 66.6954 29.7527 65.5012 32.542C64.307 35.3313 62.6522 37.7026 60.5453 39.6815C58.4384 41.6519 55.9477 43.1703 53.0817 44.245C50.2156 45.3198 47.1107 45.8572 43.767 45.8572C40.4233 45.8572 37.3184 45.3198 34.4523 44.245C31.5863 43.1703 29.1041 41.6519 27.0142 39.6815C24.9244 37.7111 23.2781 35.3313 22.0925 32.542C20.8983 29.7612 20.3012 26.6734 20.3012 23.287ZM35.4077 23.287C35.4077 24.6774 35.6209 25.9569 36.0389 27.1084C36.4569 28.26 37.0369 29.258 37.7705 30.0939C38.504 30.9298 39.3912 31.5781 40.4318 32.0387C41.4639 32.4993 42.5813 32.7211 43.7755 32.7211C44.9697 32.7211 46.0701 32.4908 47.0851 32.0387C48.1002 31.5781 48.9873 30.9384 49.7465 30.0939C50.5056 29.258 51.0857 28.26 51.5036 27.1084C51.9216 25.9569 52.1263 24.6774 52.1263 23.287C52.1263 21.9308 51.9131 20.6854 51.5036 19.5509C51.0857 18.4165 50.4971 17.4611 49.7465 16.6849C48.9873 15.9087 48.1002 15.303 47.0851 14.868C46.0701 14.433 44.9697 14.2112 43.7755 14.2112C42.5813 14.2112 41.4639 14.433 40.4318 14.868C39.3997 15.303 38.5126 15.9087 37.7705 16.6849C37.0369 17.4611 36.4569 18.4165 36.0389 19.5509C35.6124 20.6939 35.4077 21.9393 35.4077 23.287Z" fill="black" />
          <path d="M73.2634 2.38867H87.5937V32.4225H101.625V44.6629H73.2634V2.38867Z" fill="black" />
          <path d="M102.342 23.5259C102.342 20.1395 102.939 17.0773 104.133 14.3306C105.327 11.584 106.957 9.23829 109.029 7.28494C111.102 5.33159 113.533 3.83032 116.339 2.77261C119.146 1.7149 122.14 1.19458 125.33 1.19458C128.597 1.19458 131.676 1.72343 134.585 2.77261C137.494 3.83032 139.916 5.25482 141.869 7.0461L133.152 17.5549C132.359 16.5228 131.361 15.7381 130.166 15.2007C128.972 14.6633 127.642 14.3904 126.166 14.3904C124.972 14.3904 123.854 14.6036 122.822 15.0216C121.79 15.4395 120.886 16.0452 120.101 16.8384C119.325 17.6317 118.719 18.6041 118.276 19.7386C117.841 20.8731 117.619 22.1355 117.619 23.5259C117.619 24.9163 117.849 26.1616 118.31 27.262C118.77 28.3538 119.385 29.3006 120.161 30.1024C120.937 30.8957 121.841 31.5099 122.882 31.9193C123.914 32.3373 125.014 32.5505 126.166 32.5505C127.795 32.5505 129.203 32.2093 130.38 31.5355C131.557 30.8616 132.478 30.0598 133.152 29.1471L141.869 39.5962C139.959 41.5069 137.613 43.0338 134.824 44.1683C132.034 45.3027 128.87 45.8743 125.33 45.8743C122.148 45.8743 119.146 45.3369 116.339 44.2621C113.533 43.1873 111.094 41.669 109.029 39.6986C106.957 37.7282 105.327 35.3825 104.133 32.6529C102.939 29.9063 102.342 26.8696 102.342 23.5259Z" fill="black" />
          <path d="M159.723 18.6893H172.62V30.3327H159.782V44.6629H145.81V2.38867H174.351V14.2708H159.723V18.6893Z" fill="black" />
          <path d="M0.00854492 57.6625V51.6233H1.47569V57.6625H0.00854492Z" fill="black" />
          <path d="M5.43357 57.6625V55.3423C5.43357 55.2229 5.42504 55.112 5.39945 55.0012C5.38239 54.8903 5.34827 54.7964 5.29709 54.7197C5.25444 54.6344 5.1862 54.5747 5.10943 54.5235C5.02413 54.4723 4.92177 54.4552 4.79382 54.4552C4.66588 54.4552 4.56352 54.4808 4.46969 54.5235C4.37586 54.5747 4.29909 54.6344 4.23085 54.7197C4.17114 54.805 4.11996 54.8988 4.09437 55.0097C4.06025 55.1206 4.04319 55.24 4.04319 55.3594V57.6625H2.64429V53.4146H4.00054V54.0032H4.0176C4.06878 53.9093 4.13702 53.824 4.21379 53.7302C4.29909 53.6449 4.39292 53.5681 4.50381 53.5084C4.6147 53.4487 4.73412 53.3975 4.86206 53.3549C4.99001 53.3122 5.13502 53.2952 5.28003 53.2952C5.56152 53.2952 5.80888 53.3463 6.00507 53.4487C6.20126 53.5511 6.36333 53.6875 6.48275 53.8496C6.60217 54.0117 6.69599 54.1993 6.74717 54.4041C6.79835 54.6173 6.83247 54.822 6.83247 55.0353V57.6625H5.43357Z" fill="black" />
          <path d="M11.1145 57.6625V57.108H11.0974C10.961 57.3213 10.7648 57.4919 10.5089 57.6028C10.253 57.7222 9.98855 57.7819 9.69853 57.7819C9.38292 57.7819 9.09291 57.7222 8.84554 57.5943C8.59817 57.4663 8.38492 57.3042 8.21433 57.0995C8.04373 56.8948 7.90725 56.6474 7.81342 56.383C7.71959 56.1101 7.67694 55.8286 7.67694 55.53C7.67694 55.2315 7.71959 54.95 7.82195 54.677C7.91578 54.4041 8.05226 54.1738 8.22286 53.969C8.39345 53.7643 8.6067 53.6023 8.85407 53.4828C9.10144 53.3634 9.37439 53.3037 9.69 53.3037C10.0056 53.3037 10.27 53.3634 10.4833 53.4828C10.6965 53.6023 10.8671 53.7302 10.9865 53.8752H11.0036V51.2224H12.4025V57.671H11.1145V57.6625ZM11.0633 55.53C11.0633 55.3935 11.0377 55.2571 10.9951 55.1291C10.9524 55.0012 10.8757 54.8817 10.7904 54.7794C10.7051 54.677 10.5942 54.5917 10.4662 54.532C10.3383 54.4723 10.2018 54.4382 10.0397 54.4382C9.87766 54.4382 9.73265 54.4723 9.6047 54.532C9.47675 54.5917 9.37439 54.677 9.28909 54.7794C9.2038 54.8817 9.14409 54.9926 9.09291 55.1206C9.05026 55.2485 9.0332 55.385 9.0332 55.5215C9.0332 55.658 9.05026 55.7944 9.09291 55.9224C9.13556 56.0589 9.19527 56.1698 9.28909 56.2807C9.38292 56.3915 9.47675 56.4683 9.6047 56.528C9.73265 56.5877 9.87766 56.6218 10.0397 56.6218C10.2018 56.6218 10.3383 56.5877 10.4662 56.528C10.5942 56.4683 10.6965 56.383 10.7904 56.2807C10.8842 56.1783 10.9439 56.0589 10.9951 55.9309C11.0463 55.803 11.0633 55.6665 11.0633 55.53Z" fill="black" />
          <path d="M17.7252 55.5897C17.7252 55.6494 17.7252 55.7006 17.7252 55.7603C17.7252 55.82 17.7252 55.8712 17.7166 55.9223H14.6373C14.6459 56.0418 14.6885 56.1527 14.7397 56.2465C14.7909 56.3403 14.8762 56.4256 14.97 56.4939C15.0638 56.5621 15.1662 56.6218 15.2856 56.6645C15.405 56.7071 15.5244 56.7242 15.6439 56.7242C15.8656 56.7242 16.0533 56.6815 16.2068 56.5962C16.3604 56.5109 16.4883 56.4086 16.5822 56.2721L17.5546 56.8862C17.3584 57.1762 17.094 57.398 16.7613 57.5601C16.4371 57.7136 16.0533 57.7989 15.6268 57.7989C15.3112 57.7989 15.0041 57.7477 14.7226 57.6539C14.4412 57.5516 14.1938 57.4066 13.972 57.2189C13.7588 57.0312 13.5882 56.7924 13.4687 56.5194C13.3493 56.238 13.2896 55.9223 13.2896 55.5641C13.2896 55.2144 13.3493 54.8988 13.4687 54.6173C13.5882 54.3358 13.7502 54.0969 13.9549 53.9008C14.1597 53.7046 14.3985 53.551 14.68 53.4401C14.9615 53.3293 15.26 53.2781 15.5842 53.2781C15.8998 53.2781 16.1813 53.3293 16.4457 53.4316C16.7101 53.534 16.9319 53.6875 17.1195 53.8922C17.3072 54.0884 17.4522 54.3358 17.5631 54.6173C17.674 54.8988 17.7252 55.2229 17.7252 55.5897ZM16.4371 55.0523C16.4371 54.8305 16.3689 54.6429 16.2324 54.4808C16.096 54.3187 15.8827 54.242 15.6012 54.242C15.4647 54.242 15.3368 54.259 15.2259 54.3102C15.115 54.3528 15.0127 54.4126 14.9274 54.4808C14.8421 54.5576 14.7738 54.6429 14.7226 54.7452C14.6715 54.8476 14.6459 54.9499 14.6373 55.0608H16.4371V55.0523Z" fill="black" />
          <path d="M23.3208 55.5215C23.3208 55.82 23.2781 56.1015 23.1843 56.3745C23.0905 56.6474 22.9625 56.8863 22.7919 57.091C22.6213 57.2957 22.4081 57.4663 22.1522 57.5857C21.8963 57.7051 21.6148 57.7734 21.2992 57.7734C21.0348 57.7734 20.7874 57.7222 20.5571 57.6198C20.3268 57.5175 20.1391 57.3725 20.0112 57.1933H19.9941V59.7097H18.5952V53.4146H19.9259V53.9349H19.9515C20.0794 53.7643 20.2671 53.6108 20.4974 53.4914C20.7277 53.3634 21.0006 53.2952 21.3077 53.2952C21.6148 53.2952 21.8963 53.3549 22.1437 53.4743C22.391 53.5937 22.6043 53.7558 22.7834 53.9605C22.9625 54.1652 23.0905 54.4041 23.1843 54.6685C23.2781 54.9414 23.3208 55.2229 23.3208 55.5215ZM21.9645 55.5215C21.9645 55.385 21.9475 55.2485 21.9048 55.1206C21.8622 54.9926 21.8025 54.8732 21.7172 54.7794C21.6319 54.677 21.5295 54.6002 21.4101 54.532C21.2821 54.4723 21.1371 54.4382 20.9751 54.4382C20.813 54.4382 20.6765 54.4723 20.5486 54.532C20.4206 54.5917 20.3183 54.677 20.2244 54.7794C20.1306 54.8817 20.0709 55.0011 20.0197 55.1291C19.9685 55.257 19.9515 55.3935 19.9515 55.53C19.9515 55.6665 19.9771 55.803 20.0197 55.9309C20.0709 56.0589 20.1391 56.1783 20.2244 56.2806C20.3097 56.383 20.4206 56.4683 20.5486 56.528C20.6765 56.5877 20.813 56.6218 20.9751 56.6218C21.1371 56.6218 21.2821 56.5877 21.4101 56.528C21.538 56.4683 21.6404 56.383 21.7172 56.2806C21.7939 56.1783 21.8622 56.0589 21.9048 55.9224C21.9475 55.7944 21.9645 55.6579 21.9645 55.5215Z" fill="black" />
          <path d="M28.4132 55.5897C28.4132 55.6494 28.4132 55.7006 28.4132 55.7603C28.4132 55.82 28.4132 55.8712 28.4046 55.9223H25.3253C25.3339 56.0418 25.3765 56.1527 25.4277 56.2465C25.4789 56.3403 25.5642 56.4256 25.658 56.4939C25.7518 56.5621 25.8542 56.6218 25.9736 56.6645C26.093 56.7071 26.2124 56.7242 26.3319 56.7242C26.5536 56.7242 26.7413 56.6815 26.8948 56.5962C27.0484 56.5109 27.1763 56.4086 27.2701 56.2721L28.2426 56.8862C28.0464 57.1762 27.7819 57.398 27.4493 57.5601C27.1251 57.7136 26.7413 57.7989 26.3148 57.7989C25.9992 57.7989 25.6921 57.7477 25.4106 57.6539C25.1291 57.5516 24.8818 57.4066 24.66 57.2189C24.4467 57.0312 24.2761 56.7924 24.1567 56.5194C24.0373 56.238 23.9776 55.9223 23.9776 55.5641C23.9776 55.2144 24.0373 54.8988 24.1567 54.6173C24.2761 54.3358 24.4382 54.0969 24.6429 53.9008C24.8476 53.7046 25.0865 53.551 25.368 53.4401C25.6495 53.3293 25.948 53.2781 26.2721 53.2781C26.5878 53.2781 26.8692 53.3293 27.1337 53.4316C27.3981 53.534 27.6199 53.6875 27.8075 53.8922C27.9952 54.0884 28.1402 54.3358 28.2511 54.6173C28.362 54.8988 28.4132 55.2229 28.4132 55.5897ZM27.1251 55.0523C27.1251 54.8305 27.0569 54.6429 26.9204 54.4808C26.7839 54.3187 26.5707 54.242 26.2892 54.242C26.1527 54.242 26.0248 54.259 25.9139 54.3102C25.803 54.3528 25.7006 54.4126 25.6153 54.4808C25.53 54.5576 25.4618 54.6429 25.4106 54.7452C25.3594 54.8476 25.3339 54.9499 25.3253 55.0608H27.1251V55.0523Z" fill="black" />
          <path d="M32.0725 57.6625V55.3423C32.0725 55.2229 32.0639 55.112 32.0384 55.0012C32.0213 54.8903 31.9872 54.7964 31.936 54.7197C31.8933 54.6344 31.8251 54.5747 31.7483 54.5235C31.663 54.4723 31.5607 54.4552 31.4327 54.4552C31.3048 54.4552 31.2024 54.4808 31.1086 54.5235C31.0148 54.5747 30.938 54.6344 30.8698 54.7197C30.81 54.805 30.7589 54.8988 30.7333 55.0097C30.6992 55.1206 30.6821 55.24 30.6821 55.3594V57.6625H29.2747V53.4146H30.6309V54.0032H30.648C30.6992 53.9093 30.7674 53.824 30.8442 53.7302C30.9295 53.6449 31.0233 53.5681 31.1342 53.5084C31.2451 53.4487 31.3645 53.3975 31.4924 53.3549C31.6204 53.3122 31.7654 53.2952 31.9104 53.2952C32.1919 53.2952 32.4393 53.3463 32.6354 53.4487C32.8316 53.5511 32.9937 53.6875 33.1131 53.8496C33.2325 54.0117 33.3264 54.1993 33.3775 54.4041C33.4287 54.6173 33.4628 54.822 33.4628 55.0353V57.6625H32.0725Z" fill="black" />
          <path d="M37.7534 57.6625V57.108H37.7364C37.5999 57.3213 37.4037 57.4919 37.1478 57.6028C36.8919 57.7222 36.6275 57.7819 36.3374 57.7819C36.0218 57.7819 35.7318 57.7222 35.4845 57.5943C35.2371 57.4663 35.0238 57.3042 34.8532 57.0995C34.6826 56.8948 34.5462 56.6474 34.4523 56.383C34.3585 56.1101 34.3159 55.8286 34.3159 55.53C34.3159 55.2315 34.3585 54.95 34.4609 54.677C34.5547 54.4041 34.6912 54.1738 34.8618 53.969C35.0324 53.7643 35.2456 53.6023 35.493 53.4828C35.7404 53.3634 36.0133 53.3037 36.3289 53.3037C36.6445 53.3037 36.909 53.3634 37.1222 53.4828C37.3354 53.6023 37.506 53.7302 37.6255 53.8752H37.6425V51.2224H39.0414V57.671H37.7534V57.6625ZM37.7022 55.53C37.7022 55.3935 37.6766 55.2571 37.634 55.1291C37.5913 55.0012 37.5146 54.8817 37.4293 54.7794C37.344 54.677 37.2331 54.5917 37.1051 54.532C36.9772 54.4723 36.8407 54.4382 36.6786 54.4382C36.5166 54.4382 36.3716 54.4723 36.2436 54.532C36.1157 54.5917 36.0133 54.677 35.928 54.7794C35.8427 54.8817 35.783 54.9926 35.7318 55.1206C35.6892 55.2485 35.6721 55.385 35.6721 55.5215C35.6721 55.658 35.6892 55.7944 35.7318 55.9224C35.7745 56.0589 35.8342 56.1698 35.928 56.2807C36.0218 56.3915 36.1157 56.4683 36.2436 56.528C36.3716 56.5877 36.5166 56.6218 36.6786 56.6218C36.8407 56.6218 36.9772 56.5877 37.1051 56.528C37.2331 56.4683 37.3354 56.383 37.4293 56.2807C37.5231 56.1783 37.5828 56.0589 37.634 55.9309C37.6852 55.803 37.7022 55.6665 37.7022 55.53Z" fill="black" />
          <path d="M44.3641 55.5897C44.3641 55.6494 44.3641 55.7006 44.3641 55.7603C44.3641 55.82 44.3641 55.8712 44.3556 55.9223H41.2763C41.2848 56.0418 41.3274 56.1527 41.3786 56.2465C41.4298 56.3403 41.5151 56.4256 41.6089 56.4939C41.7027 56.5621 41.8051 56.6218 41.9245 56.6645C42.0439 56.7071 42.1634 56.7242 42.2828 56.7242C42.5046 56.7242 42.6922 56.6815 42.8458 56.5962C42.9993 56.5109 43.1272 56.4086 43.2211 56.2721L44.1935 56.8862C43.9973 57.1762 43.7329 57.398 43.4002 57.5601C43.0761 57.7136 42.6922 57.7989 42.2657 57.7989C41.9501 57.7989 41.643 57.7477 41.3616 57.6539C41.0801 57.5516 40.8327 57.4066 40.6109 57.2189C40.3977 57.0312 40.2271 56.7924 40.1077 56.5194C39.9882 56.238 39.9285 55.9223 39.9285 55.5641C39.9285 55.2144 39.9882 54.8988 40.1077 54.6173C40.2271 54.3358 40.3891 54.0969 40.5939 53.9008C40.7986 53.7046 41.0374 53.551 41.3189 53.4401C41.6004 53.3293 41.8989 53.2781 42.2231 53.2781C42.5387 53.2781 42.8202 53.3293 43.0846 53.4316C43.349 53.534 43.5708 53.6875 43.7585 53.8922C43.9461 54.0884 44.0911 54.3358 44.202 54.6173C44.3129 54.8988 44.3641 55.2229 44.3641 55.5897ZM43.0761 55.0523C43.0761 54.8305 43.0078 54.6429 42.8713 54.4808C42.7349 54.3187 42.5216 54.242 42.2401 54.242C42.1037 54.242 41.9757 54.259 41.8648 54.3102C41.7539 54.3528 41.6516 54.4126 41.5663 54.4808C41.481 54.5576 41.4127 54.6429 41.3616 54.7452C41.3104 54.8476 41.2848 54.9499 41.2763 55.0608H43.0761V55.0523Z" fill="black" />
          <path d="M48.0234 57.6625V55.3423C48.0234 55.2229 48.0149 55.112 47.9893 55.0012C47.9722 54.8903 47.9381 54.7964 47.8869 54.7197C47.8443 54.6344 47.776 54.5747 47.6993 54.5235C47.614 54.4723 47.5116 54.4552 47.3837 54.4552C47.2557 54.4552 47.1533 54.4808 47.0595 54.5235C46.9657 54.5747 46.8889 54.6344 46.8207 54.7197C46.761 54.805 46.7098 54.8988 46.6842 55.0097C46.6501 55.1206 46.633 55.24 46.633 55.3594V57.6625H45.2256V53.4146H46.5818V54.0032H46.5989C46.6501 53.9093 46.7183 53.824 46.7951 53.7302C46.8804 53.6449 46.9742 53.5681 47.0851 53.5084C47.196 53.4487 47.3154 53.3975 47.4434 53.3549C47.5713 53.3122 47.7163 53.2952 47.8613 53.2952C48.1428 53.2952 48.3902 53.3463 48.5864 53.4487C48.7826 53.5511 48.9446 53.6875 49.064 53.8496C49.1835 54.0117 49.2773 54.1993 49.3285 54.4041C49.3797 54.6173 49.4138 54.822 49.4138 55.0353V57.6625H48.0234Z" fill="black" />
          <path d="M52.0069 54.4552V56.0844C52.0069 56.2806 52.041 56.4341 52.1263 56.5365C52.2031 56.6388 52.3396 56.6815 52.5358 56.6815C52.604 56.6815 52.6808 56.673 52.749 56.6644C52.8173 56.6559 52.894 56.6388 52.9452 56.6133L52.9623 57.6368C52.8684 57.671 52.7405 57.7051 52.5955 57.7307C52.4505 57.7563 52.2969 57.7648 52.1519 57.7648C51.8704 57.7648 51.6316 57.7307 51.4354 57.6624C51.2392 57.5942 51.0857 57.4918 50.9748 57.3554C50.8639 57.2189 50.7786 57.0653 50.7274 56.8777C50.6762 56.69 50.6507 56.4853 50.6507 56.255V54.4552H49.9683V53.4145H50.6421V52.2971H52.0154V53.4145H53.0134V54.4552H52.0069Z" fill="black" />
          <path d="M62.166 54.6172C62.166 55.0949 62.0807 55.5299 61.9187 55.9309C61.7566 56.3232 61.5263 56.6644 61.2363 56.9459C60.9462 57.2274 60.5965 57.4406 60.1871 57.5942C59.7862 57.7477 59.3426 57.8245 58.8735 57.8245C58.4043 57.8245 57.9693 57.7477 57.5599 57.5942C57.159 57.4406 56.8092 57.2274 56.5192 56.9459C56.2292 56.6644 55.9989 56.3232 55.8283 55.9309C55.6662 55.5385 55.5809 55.1035 55.5809 54.6172C55.5809 54.131 55.6662 53.696 55.8283 53.3036C55.9904 52.9113 56.2207 52.5871 56.5192 52.3142C56.8092 52.0412 57.159 51.828 57.5599 51.683C57.9608 51.5379 58.3958 51.4612 58.8735 51.4612C59.3511 51.4612 59.7862 51.5379 60.1871 51.683C60.588 51.828 60.9377 52.0412 61.2363 52.3142C61.5263 52.5871 61.7566 52.9198 61.9187 53.3036C62.0807 53.696 62.166 54.131 62.166 54.6172ZM60.605 54.6172C60.605 54.3528 60.5624 54.114 60.4771 53.8837C60.3918 53.6534 60.2724 53.4572 60.1188 53.2951C59.9653 53.133 59.7862 53.0051 59.5729 52.9113C59.3597 52.8174 59.1208 52.7663 58.8649 52.7663C58.609 52.7663 58.3787 52.8174 58.1655 52.9113C57.9522 53.0051 57.7731 53.133 57.6196 53.2951C57.466 53.4572 57.3466 53.6534 57.2613 53.8837C57.176 54.114 57.1504 54.3528 57.1504 54.6172C57.1504 54.8902 57.1931 55.1461 57.2784 55.3764C57.3637 55.6067 57.4831 55.8029 57.6366 55.965C57.7902 56.127 57.9693 56.2635 58.1826 56.3573C58.3958 56.4512 58.6261 56.5024 58.882 56.5024C59.1379 56.5024 59.3682 56.4597 59.5815 56.3573C59.7947 56.2635 59.9738 56.1356 60.1274 55.965C60.2809 55.7944 60.4003 55.6067 60.4856 55.3764C60.5624 55.1376 60.605 54.8902 60.605 54.6172Z" fill="black" />
          <path d="M65.8083 54.5661C65.7486 54.5491 65.6804 54.5405 65.6206 54.532C65.5609 54.5235 65.5012 54.5235 65.4501 54.5235C65.2624 54.5235 65.1003 54.5576 64.9724 54.6258C64.8444 54.6941 64.7421 54.7794 64.6653 54.8732C64.5885 54.967 64.5288 55.0779 64.4947 55.1888C64.4606 55.2997 64.4435 55.4021 64.4435 55.4874V57.6625H63.0446V53.4231H64.3923V54.0373H64.4094C64.5203 53.824 64.6738 53.6449 64.87 53.5084C65.0662 53.3719 65.2965 53.3037 65.5609 53.3037C65.6206 53.3037 65.6718 53.3037 65.7315 53.3122C65.7827 53.3208 65.8254 53.3293 65.8595 53.3378L65.8083 54.5661Z" fill="black" />
          <path d="M70.7812 57.2616C70.7812 57.6796 70.7215 58.0378 70.6021 58.3449C70.4827 58.652 70.3121 58.9079 70.0988 59.1126C69.8856 59.3173 69.6212 59.4623 69.3056 59.5647C68.9985 59.667 68.6573 59.7182 68.282 59.7182C67.9067 59.7182 67.5228 59.6585 67.1219 59.5476C66.7295 59.4367 66.3883 59.2661 66.0983 59.0529L66.7978 58.0549C67.0025 58.234 67.2243 58.3705 67.4716 58.4558C67.719 58.5411 67.9664 58.5923 68.1967 58.5923C68.6061 58.5923 68.9047 58.4814 69.1008 58.2681C69.297 58.0549 69.3994 57.7649 69.3994 57.4066V57.1507H69.3738C69.2459 57.3213 69.0667 57.4578 68.8449 57.5601C68.6232 57.6625 68.3673 57.7137 68.0858 57.7137C67.7616 57.7137 67.4716 57.654 67.2243 57.5345C66.9769 57.4151 66.7636 57.2616 66.593 57.0654C66.4224 56.8692 66.286 56.6389 66.1921 56.3659C66.0983 56.1015 66.0557 55.82 66.0557 55.53C66.0557 55.24 66.0983 54.95 66.1921 54.6856C66.286 54.4211 66.4139 54.1823 66.593 53.9776C66.7636 53.7729 66.9769 53.6108 67.2243 53.4828C67.4716 53.3634 67.7616 53.3037 68.0772 53.3037C68.3417 53.3037 68.5976 53.3549 68.8449 53.4658C69.0923 53.5767 69.2885 53.7387 69.4335 53.969H69.4506V53.4231H70.7727V57.2616H70.7812ZM69.4506 55.513C69.4506 55.3679 69.425 55.2315 69.3823 55.1035C69.3397 54.9756 69.2629 54.8562 69.1691 54.7538C69.0752 54.6514 68.9729 54.5747 68.8449 54.5235C68.717 54.4723 68.5805 54.4382 68.4184 54.4382C68.2564 54.4382 68.1199 54.4638 67.992 54.5235C67.8725 54.5832 67.7702 54.66 67.6849 54.7538C67.5996 54.8476 67.5313 54.967 67.4887 55.095C67.446 55.2229 67.4204 55.3679 67.4204 55.513C67.4204 55.658 67.446 55.7944 67.4887 55.9224C67.5313 56.0503 67.5996 56.1698 67.6849 56.2721C67.7702 56.3745 67.8725 56.4512 67.992 56.511C68.1114 56.5707 68.2564 56.6048 68.4184 56.6048C68.5805 56.6048 68.717 56.5707 68.8449 56.511C68.9729 56.4512 69.0838 56.3745 69.1691 56.2807C69.2544 56.1868 69.3226 56.0674 69.3738 55.9395C69.425 55.8115 69.4506 55.658 69.4506 55.513Z" fill="black" />
          <path d="M71.8304 53.9946C72.0778 53.7558 72.3763 53.5766 72.709 53.4572C73.0416 53.3378 73.3828 53.2781 73.724 53.2781C74.0823 53.2781 74.3808 53.3207 74.6282 53.406C74.8756 53.4913 75.0718 53.6278 75.2338 53.8155C75.3959 54.0031 75.4983 54.2334 75.575 54.5149C75.6433 54.7964 75.6774 55.1291 75.6774 55.5129V57.6539H74.3979V57.2018H74.3723C74.2614 57.381 74.0994 57.5174 73.8861 57.6113C73.6643 57.7051 73.4255 57.7563 73.1781 57.7563C73.0075 57.7563 72.8284 57.7307 72.6493 57.688C72.4701 57.6454 72.2995 57.5686 72.1545 57.4663C72.001 57.3639 71.8816 57.2274 71.7792 57.0568C71.6769 56.8862 71.6342 56.673 71.6342 56.4256C71.6342 56.1185 71.7195 55.8712 71.8816 55.6835C72.0522 55.4959 72.2654 55.3508 72.5299 55.2485C72.7943 55.1461 73.0928 55.0779 73.417 55.0438C73.7411 55.0096 74.0567 54.9926 74.3638 54.9926V54.9329C74.3638 54.7196 74.287 54.5661 74.142 54.4723C73.997 54.3699 73.8093 54.3273 73.5961 54.3273C73.3999 54.3273 73.2037 54.3699 73.0246 54.4552C72.8369 54.5405 72.6834 54.6429 72.5469 54.7623L71.8304 53.9946ZM74.3979 55.8115H74.2188C74.0652 55.8115 73.9117 55.82 73.7582 55.837C73.6046 55.8541 73.4596 55.8797 73.3402 55.9138C73.2208 55.9479 73.1184 56.0162 73.0416 56.0929C72.9649 56.1697 72.9222 56.2721 72.9222 56.3915C72.9222 56.4683 72.9393 56.5365 72.9734 56.5962C73.0075 56.6559 73.0587 56.6986 73.1184 56.7327C73.1781 56.7668 73.2378 56.7924 73.3146 56.8009C73.3914 56.818 73.4596 56.818 73.5279 56.818C73.8093 56.818 74.0311 56.7412 74.1847 56.5877C74.3382 56.4341 74.415 56.2209 74.415 55.9479V55.8115H74.3979Z" fill="black" />
          <path d="M79.5073 57.6625V55.3423C79.5073 55.2229 79.4988 55.112 79.4732 55.0012C79.4561 54.8903 79.422 54.7964 79.3708 54.7197C79.3282 54.6344 79.2599 54.5747 79.1831 54.5235C79.0978 54.4723 78.9955 54.4552 78.8675 54.4552C78.7396 54.4552 78.6372 54.4808 78.5434 54.5235C78.4496 54.5747 78.3728 54.6344 78.3046 54.7197C78.2449 54.805 78.1937 54.8988 78.1681 55.0097C78.134 55.1206 78.1169 55.24 78.1169 55.3594V57.6625H76.7095V53.4146H78.0657V54.0032H78.0828C78.134 53.9093 78.2022 53.824 78.279 53.7302C78.3643 53.6449 78.4581 53.5681 78.569 53.5084C78.6799 53.4487 78.7993 53.3975 78.9272 53.3549C79.0552 53.3122 79.2002 53.2952 79.3452 53.2952C79.6267 53.2952 79.8741 53.3463 80.0703 53.4487C80.2664 53.5511 80.4285 53.6875 80.5479 53.8496C80.6674 54.0117 80.7612 54.1993 80.8124 54.4041C80.8635 54.6173 80.8977 54.822 80.8977 55.0353V57.6625H79.5073Z" fill="black" />
          <path d="M83.4908 52.0582C83.4908 52.1691 83.4737 52.2715 83.4311 52.3653C83.3884 52.4591 83.3287 52.5444 83.2605 52.6127C83.1922 52.6809 83.1069 52.7321 83.0046 52.7747C82.9022 52.8174 82.7998 52.8344 82.689 52.8344C82.4587 52.8344 82.271 52.7577 82.1175 52.6127C81.9639 52.4677 81.8871 52.2629 81.8871 52.0582C81.8871 51.9559 81.9042 51.862 81.9469 51.7682C81.9895 51.6744 82.0407 51.5891 82.1175 51.5208C82.1942 51.4526 82.2795 51.4014 82.3734 51.3588C82.4672 51.3161 82.5781 51.2905 82.689 51.2905C82.7998 51.2905 82.9022 51.3076 83.0046 51.3502C83.1069 51.3929 83.1922 51.4441 83.2605 51.5123C83.3287 51.5805 83.3884 51.6573 83.4311 51.7597C83.4737 51.845 83.4908 51.9473 83.4908 52.0582ZM81.9895 57.6624V53.4145H83.3884V57.6624H81.9895Z" fill="black" />
          <path d="M84.2414 57.6624V56.5706L86.2374 54.4893H84.3182V53.4146H87.9434V54.4637L85.8877 56.5792H88.0287V57.6624H84.2414Z" fill="black" />
          <path d="M88.8049 53.9946C89.0523 53.7558 89.3508 53.5766 89.6835 53.4572C90.0161 53.3378 90.3573 53.2781 90.6985 53.2781C91.0568 53.2781 91.3553 53.3207 91.6027 53.406C91.8501 53.4913 92.0463 53.6278 92.2083 53.8155C92.3704 54.0031 92.4728 54.2334 92.5495 54.5149C92.6178 54.7964 92.6519 55.1291 92.6519 55.5129V57.6539H91.3724V57.2018H91.3468C91.2359 57.381 91.0738 57.5174 90.8606 57.6113C90.6388 57.7051 90.4 57.7563 90.1526 57.7563C89.982 57.7563 89.8029 57.7307 89.6238 57.688C89.4446 57.6454 89.274 57.5686 89.129 57.4663C88.9755 57.3639 88.8561 57.2274 88.7537 57.0568C88.6514 56.8862 88.6087 56.673 88.6087 56.4256C88.6087 56.1185 88.694 55.8712 88.8561 55.6835C89.0267 55.4959 89.2399 55.3508 89.5043 55.2485C89.7688 55.1461 90.0673 55.0779 90.3915 55.0438C90.7156 55.0096 91.0312 54.9926 91.3383 54.9926V54.9329C91.3383 54.7196 91.2615 54.5661 91.1165 54.4723C90.9715 54.3699 90.7838 54.3273 90.5706 54.3273C90.3744 54.3273 90.1782 54.3699 89.9991 54.4552C89.8114 54.5405 89.6579 54.6429 89.5214 54.7623L88.8049 53.9946ZM91.3724 55.8115H91.1933C91.0397 55.8115 90.8862 55.82 90.7327 55.837C90.5791 55.8541 90.4341 55.8797 90.3147 55.9138C90.1953 55.9479 90.0929 56.0162 90.0161 56.0929C89.9394 56.1697 89.8967 56.2721 89.8967 56.3915C89.8967 56.4683 89.9138 56.5365 89.9479 56.5962C89.982 56.6559 90.0332 56.6986 90.0929 56.7327C90.1526 56.7668 90.2123 56.7924 90.2891 56.8009C90.3659 56.818 90.4341 56.818 90.5023 56.818C90.7838 56.818 91.0056 56.7412 91.1592 56.5877C91.3127 56.4341 91.3895 56.2209 91.3895 55.9479V55.8115H91.3724Z" fill="black" />
          <path d="M95.1256 54.4552V56.0844C95.1256 56.2806 95.1597 56.4341 95.245 56.5365C95.3218 56.6388 95.4582 56.6815 95.6544 56.6815C95.7227 56.6815 95.7994 56.673 95.8677 56.6644C95.9359 56.6559 96.0127 56.6388 96.0639 56.6133L96.0809 57.6368C95.9871 57.671 95.8591 57.7051 95.7141 57.7307C95.5691 57.7563 95.4156 57.7648 95.2706 57.7648C94.9891 57.7648 94.7502 57.7307 94.5541 57.6624C94.3579 57.5942 94.2043 57.4918 94.0934 57.3554C93.9826 57.2189 93.8973 57.0653 93.8461 56.8777C93.7949 56.69 93.7693 56.4853 93.7693 56.255V54.4552H93.0869V53.4145H93.7608V52.2971H95.1341V53.4145H96.1321V54.4552H95.1256Z" fill="black" />
          <path d="M98.2987 52.0582C98.2987 52.1691 98.2816 52.2715 98.239 52.3653C98.1963 52.4591 98.1366 52.5444 98.0684 52.6127C98.0001 52.6809 97.9148 52.7321 97.8125 52.7747C97.7101 52.8174 97.6078 52.8345 97.4969 52.8345C97.2666 52.8345 97.0789 52.7577 96.9254 52.6127C96.7718 52.4677 96.6951 52.28 96.6951 52.0668C96.6951 51.9644 96.7121 51.8706 96.7548 51.7767C96.7974 51.6829 96.8486 51.5976 96.9254 51.5294C97.0021 51.4611 97.0874 51.41 97.1813 51.3673C97.2751 51.3247 97.386 51.2991 97.4969 51.2991C97.6078 51.2991 97.7101 51.3161 97.8125 51.3588C97.9148 51.4014 98.0001 51.4526 98.0684 51.5209C98.1366 51.5891 98.1963 51.6659 98.239 51.7682C98.2816 51.845 98.2987 51.9473 98.2987 52.0582ZM96.7974 57.6624V53.4145H98.1963V57.6624H96.7974Z" fill="black" />
          <path d="M103.809 55.5214C103.809 55.8712 103.749 56.1782 103.621 56.4597C103.493 56.7412 103.323 56.9801 103.11 57.1762C102.896 57.3724 102.64 57.526 102.359 57.6369C102.077 57.7478 101.77 57.7989 101.446 57.7989C101.131 57.7989 100.824 57.7478 100.534 57.6369C100.244 57.526 99.9962 57.381 99.7829 57.1762C99.5697 56.9801 99.3991 56.7412 99.2711 56.4597C99.1432 56.1782 99.0835 55.8626 99.0835 55.5214C99.0835 55.1717 99.1432 54.8646 99.2711 54.5832C99.3991 54.3017 99.5697 54.0714 99.7829 53.8752C99.9962 53.679 100.244 53.534 100.534 53.4316C100.824 53.3293 101.122 53.2781 101.446 53.2781C101.77 53.2781 102.077 53.3293 102.359 53.4316C102.64 53.534 102.896 53.679 103.11 53.8752C103.323 54.0714 103.493 54.3017 103.621 54.5832C103.749 54.8561 103.809 55.1717 103.809 55.5214ZM102.461 55.5214C102.461 55.385 102.436 55.2485 102.393 55.1205C102.35 54.9926 102.282 54.8732 102.197 54.7793C102.112 54.677 102.009 54.6002 101.881 54.532C101.753 54.4637 101.608 54.4381 101.446 54.4381C101.284 54.4381 101.139 54.4723 101.011 54.532C100.883 54.5917 100.781 54.677 100.704 54.7793C100.619 54.8817 100.559 54.9926 100.517 55.1205C100.474 55.2485 100.457 55.385 100.457 55.5214C100.457 55.6579 100.474 55.7944 100.517 55.9223C100.559 56.0503 100.619 56.1697 100.713 56.2806C100.798 56.383 100.9 56.4683 101.028 56.528C101.156 56.5877 101.301 56.6218 101.463 56.6218C101.625 56.6218 101.77 56.5877 101.898 56.528C102.026 56.4683 102.129 56.383 102.214 56.2806C102.299 56.1782 102.359 56.0588 102.402 55.9223C102.444 55.7859 102.461 55.6579 102.461 55.5214Z" fill="black" />
          <path d="M107.485 57.6625V55.3423C107.485 55.2229 107.477 55.112 107.451 55.0012C107.434 54.8903 107.4 54.7964 107.349 54.7197C107.306 54.6344 107.238 54.5747 107.161 54.5235C107.085 54.4723 106.974 54.4552 106.846 54.4552C106.718 54.4552 106.607 54.4808 106.522 54.5235C106.428 54.5747 106.351 54.6344 106.283 54.7197C106.223 54.805 106.172 54.8988 106.138 55.0097C106.104 55.1206 106.095 55.24 106.095 55.3594V57.6625H104.688V53.4146H106.044V54.0032H106.061C106.112 53.9093 106.18 53.824 106.266 53.7302C106.351 53.6449 106.445 53.5681 106.556 53.5084C106.667 53.4487 106.786 53.3975 106.914 53.3549C107.042 53.3122 107.187 53.2952 107.332 53.2952C107.613 53.2952 107.861 53.3463 108.057 53.4487C108.253 53.5511 108.415 53.6875 108.543 53.8496C108.663 54.0117 108.756 54.1993 108.808 54.4041C108.859 54.6173 108.884 54.822 108.884 55.0353V57.6625H107.485Z" fill="black" />
          <path d="M116.587 55.5214C116.587 55.8712 116.527 56.1782 116.399 56.4597C116.271 56.7412 116.101 56.9801 115.887 57.1762C115.674 57.3724 115.418 57.526 115.137 57.6369C114.855 57.7478 114.548 57.7989 114.224 57.7989C113.908 57.7989 113.601 57.7478 113.311 57.6369C113.021 57.526 112.774 57.381 112.561 57.1762C112.347 56.9801 112.177 56.7412 112.057 56.4597C111.93 56.1782 111.87 55.8626 111.87 55.5214C111.87 55.1717 111.93 54.8646 112.057 54.5832C112.185 54.3017 112.347 54.0714 112.561 53.8752C112.774 53.679 113.021 53.534 113.311 53.4316C113.601 53.3293 113.9 53.2781 114.224 53.2781C114.548 53.2781 114.855 53.3293 115.137 53.4316C115.418 53.534 115.674 53.679 115.887 53.8752C116.101 54.0714 116.271 54.3017 116.399 54.5832C116.527 54.8561 116.587 55.1717 116.587 55.5214ZM115.239 55.5214C115.239 55.385 115.214 55.2485 115.171 55.1205C115.128 54.9926 115.06 54.8732 114.975 54.7793C114.889 54.677 114.787 54.6002 114.659 54.532C114.531 54.4637 114.386 54.4381 114.224 54.4381C114.062 54.4381 113.917 54.4723 113.789 54.532C113.661 54.5917 113.559 54.677 113.482 54.7793C113.397 54.8817 113.337 54.9926 113.294 55.1205C113.252 55.2485 113.235 55.385 113.235 55.5214C113.235 55.6579 113.252 55.7944 113.294 55.9223C113.337 56.0503 113.397 56.1697 113.491 56.2806C113.576 56.383 113.678 56.4683 113.806 56.528C113.934 56.5877 114.079 56.6218 114.241 56.6218C114.403 56.6218 114.548 56.5877 114.676 56.528C114.804 56.4683 114.906 56.383 114.992 56.2806C115.077 56.1782 115.137 56.0588 115.179 55.9223C115.222 55.7859 115.239 55.6579 115.239 55.5214Z" fill="black" />
          <path d="M120.11 52.2459C120.059 52.2289 119.999 52.2118 119.931 52.2033C119.862 52.1948 119.794 52.1862 119.726 52.1862C119.598 52.1862 119.487 52.2118 119.41 52.2545C119.333 52.2971 119.265 52.3568 119.223 52.4336C119.18 52.5104 119.146 52.5957 119.129 52.6895C119.112 52.7833 119.103 52.8857 119.103 52.9795V53.406H120.05V54.4466H119.103V57.6539H117.704V54.4466H116.928V53.406H117.704V52.9966C117.704 52.7321 117.73 52.4848 117.79 52.2459C117.849 52.0071 117.943 51.8024 118.088 51.6232C118.225 51.4441 118.412 51.2991 118.651 51.1968C118.89 51.0944 119.18 51.0432 119.538 51.0432C119.658 51.0432 119.777 51.0517 119.896 51.0688C120.016 51.0859 120.118 51.1029 120.221 51.1285L120.11 52.2459Z" fill="black" />
          <path d="M122.993 57.6625V51.6233H124.46V56.3915H126.797V57.6625H122.993Z" fill="black" />
          <path d="M128.921 52.0582C128.921 52.1691 128.895 52.2715 128.853 52.3653C128.81 52.4591 128.75 52.5444 128.682 52.6127C128.614 52.6809 128.529 52.7321 128.426 52.7747C128.324 52.8174 128.222 52.8345 128.119 52.8345C127.889 52.8345 127.701 52.7577 127.548 52.6127C127.394 52.4591 127.317 52.28 127.317 52.0668C127.317 51.9644 127.335 51.8706 127.377 51.7767C127.42 51.6829 127.471 51.5976 127.548 51.5294C127.625 51.4611 127.71 51.41 127.804 51.3673C127.897 51.3247 128.008 51.2991 128.119 51.2991C128.23 51.2991 128.333 51.3161 128.426 51.3588C128.529 51.4014 128.614 51.4526 128.682 51.5209C128.75 51.5891 128.81 51.6659 128.853 51.7682C128.895 51.845 128.921 51.9473 128.921 52.0582ZM127.42 57.6624V53.4145H128.819V57.6624H127.42Z" fill="black" />
          <path d="M131.446 54.4552V56.0844C131.446 56.2806 131.48 56.4341 131.557 56.5365C131.634 56.6388 131.77 56.6815 131.975 56.6815C132.043 56.6815 132.12 56.673 132.188 56.6644C132.256 56.6559 132.333 56.6388 132.376 56.6133L132.393 57.6368C132.299 57.671 132.171 57.7051 132.026 57.7307C131.881 57.7563 131.727 57.7648 131.582 57.7648C131.301 57.7648 131.062 57.7307 130.866 57.6624C130.67 57.5942 130.516 57.4918 130.405 57.3554C130.294 57.2189 130.201 57.0653 130.158 56.8777C130.107 56.69 130.081 56.4853 130.081 56.255V54.4552H129.399V53.4145H130.073V52.2971H131.446V53.4145H132.444V54.4552H131.446Z" fill="black" />
          <path d="M134.414 54.4552V56.0844C134.414 56.2806 134.448 56.4341 134.525 56.5365C134.602 56.6388 134.739 56.6815 134.943 56.6815C135.011 56.6815 135.088 56.673 135.156 56.6644C135.225 56.6559 135.301 56.6388 135.344 56.6133L135.361 57.6368C135.267 57.671 135.139 57.7051 134.994 57.7307C134.849 57.7563 134.696 57.7648 134.551 57.7648C134.269 57.7648 134.031 57.7307 133.834 57.6624C133.638 57.5942 133.485 57.4918 133.374 57.3554C133.263 57.2189 133.169 57.0653 133.126 56.8777C133.075 56.69 133.05 56.4853 133.05 56.255V54.4552H132.367V53.4145H133.041V52.2971H134.414V53.4145H135.412V54.4552H134.414Z" fill="black" />
          <path d="M136.086 57.6625V51.2139H137.511V57.6625H136.086Z" fill="black" />
          <path d="M142.825 55.5897C142.825 55.6494 142.825 55.7006 142.825 55.7603C142.825 55.82 142.825 55.8712 142.816 55.9223H139.737C139.746 56.0418 139.78 56.1527 139.839 56.2465C139.899 56.3403 139.976 56.4256 140.07 56.4939C140.164 56.5621 140.266 56.6218 140.385 56.6645C140.505 56.7071 140.624 56.7242 140.752 56.7242C140.974 56.7242 141.162 56.6815 141.315 56.5962C141.469 56.5109 141.597 56.4086 141.69 56.2721L142.663 56.8862C142.467 57.1762 142.202 57.398 141.878 57.5601C141.554 57.7136 141.17 57.7989 140.744 57.7989C140.428 57.7989 140.121 57.7477 139.839 57.6539C139.558 57.5516 139.311 57.4066 139.089 57.2189C138.876 57.0312 138.705 56.7924 138.585 56.5194C138.466 56.238 138.398 55.9223 138.398 55.5641C138.398 55.2144 138.458 54.8988 138.577 54.6173C138.696 54.3358 138.858 54.0969 139.063 53.9008C139.268 53.7046 139.507 53.551 139.788 53.4401C140.07 53.3293 140.368 53.2781 140.692 53.2781C141.008 53.2781 141.289 53.3293 141.554 53.4316C141.818 53.534 142.04 53.6875 142.228 53.8922C142.415 54.0969 142.56 54.3358 142.663 54.6173C142.774 54.8988 142.825 55.2229 142.825 55.5897ZM141.537 55.0523C141.537 54.8305 141.469 54.6429 141.324 54.4808C141.187 54.3187 140.974 54.242 140.692 54.242C140.556 54.242 140.428 54.259 140.317 54.3102C140.206 54.3528 140.104 54.4126 140.019 54.4808C139.933 54.5576 139.865 54.6429 139.814 54.7452C139.763 54.8476 139.737 54.9499 139.728 55.0608H141.537V55.0523Z" fill="black" />
          <path d="M150.229 57.5515C149.836 57.7307 149.384 57.8245 148.873 57.8245C148.403 57.8245 147.977 57.7477 147.585 57.5942C147.192 57.4406 146.842 57.2274 146.552 56.9459C146.262 56.6644 146.032 56.3232 145.87 55.9309C145.708 55.5385 145.623 55.1035 145.623 54.6343C145.623 54.1481 145.708 53.7131 145.87 53.3207C146.041 52.9283 146.271 52.5957 146.561 52.3227C146.851 52.0497 147.201 51.8365 147.602 51.683C148.003 51.5379 148.429 51.4612 148.89 51.4612C149.316 51.4612 149.734 51.5379 150.144 51.683C150.553 51.828 150.894 52.0497 151.142 52.3398L150.152 53.3292C150.016 53.1416 149.836 53.0051 149.615 52.9113C149.393 52.8174 149.163 52.7748 148.932 52.7748C148.676 52.7748 148.438 52.8174 148.224 52.9198C148.011 53.0136 147.832 53.1416 147.678 53.3122C147.525 53.4828 147.405 53.6704 147.32 53.8922C147.235 54.114 147.192 54.3614 147.192 54.6343C147.192 54.9073 147.235 55.1546 147.32 55.3849C147.405 55.6152 147.525 55.8114 147.678 55.965C147.832 56.127 148.011 56.255 148.216 56.3488C148.42 56.4426 148.659 56.4853 148.907 56.4853C149.197 56.4853 149.453 56.4256 149.666 56.3147C149.879 56.2038 150.058 56.0503 150.186 55.8711L151.201 56.8265C150.954 57.1336 150.621 57.3724 150.229 57.5515Z" fill="black" />
          <path d="M151.551 53.9946C151.798 53.7558 152.088 53.5766 152.421 53.4572C152.754 53.3378 153.095 53.2781 153.445 53.2781C153.803 53.2781 154.11 53.3207 154.357 53.406C154.605 53.4913 154.801 53.6278 154.954 53.8155C155.108 54.0031 155.219 54.2334 155.296 54.5149C155.364 54.7964 155.398 55.1291 155.398 55.5129V57.6539H154.118V57.2018H154.093C153.982 57.381 153.82 57.5174 153.598 57.6113C153.376 57.7051 153.146 57.7563 152.89 57.7563C152.72 57.7563 152.54 57.7307 152.361 57.688C152.182 57.6454 152.012 57.5686 151.867 57.4663C151.722 57.3639 151.594 57.2274 151.5 57.0568C151.406 56.8862 151.355 56.673 151.355 56.4256C151.355 56.1185 151.44 55.8712 151.602 55.6835C151.773 55.4959 151.986 55.3508 152.25 55.2485C152.515 55.1461 152.813 55.0779 153.137 55.0438C153.462 55.0096 153.777 54.9926 154.084 54.9926V54.9329C154.084 54.7196 154.008 54.5661 153.863 54.4723C153.718 54.3699 153.53 54.3273 153.317 54.3273C153.12 54.3273 152.924 54.3699 152.745 54.4552C152.557 54.5405 152.404 54.6429 152.276 54.7623L151.551 53.9946ZM154.118 55.8115H153.939C153.786 55.8115 153.632 55.82 153.479 55.837C153.325 55.8541 153.18 55.8797 153.061 55.9138C152.941 55.9479 152.839 56.0162 152.762 56.0929C152.685 56.1697 152.643 56.2721 152.643 56.3915C152.643 56.4683 152.66 56.5365 152.702 56.5962C152.737 56.6559 152.788 56.6986 152.847 56.7327C152.907 56.7668 152.967 56.7924 153.044 56.8009C153.12 56.8095 153.189 56.818 153.257 56.818C153.538 56.818 153.76 56.7412 153.914 56.5877C154.067 56.4341 154.135 56.2209 154.135 55.9479V55.8115H154.118Z" fill="black" />
          <path d="M160.652 55.5897C160.652 55.6494 160.652 55.7006 160.652 55.7603C160.652 55.82 160.652 55.8712 160.644 55.9223H157.565C157.573 56.0418 157.607 56.1527 157.667 56.2465C157.727 56.3403 157.803 56.4256 157.897 56.4939C157.991 56.5621 158.093 56.6218 158.213 56.6645C158.332 56.7071 158.452 56.7242 158.58 56.7242C158.801 56.7242 158.989 56.6815 159.143 56.5962C159.296 56.5109 159.424 56.4086 159.518 56.2721L160.49 56.8862C160.294 57.1762 160.03 57.398 159.706 57.5601C159.381 57.7136 158.998 57.7989 158.571 57.7989C158.255 57.7989 157.948 57.7477 157.667 57.6539C157.385 57.5516 157.138 57.4066 156.916 57.2189C156.703 57.0312 156.532 56.7924 156.413 56.5194C156.294 56.238 156.225 55.9223 156.225 55.5641C156.225 55.2144 156.285 54.8988 156.404 54.6173C156.524 54.3358 156.686 54.0969 156.891 53.9008C157.095 53.7046 157.334 53.551 157.616 53.4401C157.897 53.3293 158.196 53.2781 158.52 53.2781C158.835 53.2781 159.117 53.3293 159.381 53.4316C159.646 53.534 159.868 53.6875 160.055 53.8922C160.243 54.0969 160.388 54.3358 160.49 54.6173C160.601 54.8988 160.652 55.2229 160.652 55.5897ZM159.364 55.0523C159.364 54.8305 159.296 54.6429 159.151 54.4808C159.015 54.3187 158.801 54.242 158.52 54.242C158.383 54.242 158.255 54.259 158.145 54.3102C158.034 54.3528 157.931 54.4126 157.846 54.4808C157.761 54.5576 157.692 54.6429 157.641 54.7452C157.59 54.8476 157.565 54.9499 157.556 55.0608H159.364V55.0523Z" fill="black" />
          <path d="M164.022 54.6684C163.911 54.5576 163.774 54.4723 163.621 54.3955C163.467 54.3187 163.305 54.2846 163.135 54.2846C163.007 54.2846 162.887 54.3102 162.776 54.3614C162.665 54.4126 162.606 54.4979 162.606 54.6258C162.606 54.7452 162.665 54.8305 162.785 54.8817C162.904 54.9329 163.109 54.9926 163.382 55.0523C163.544 55.0864 163.706 55.1376 163.86 55.1973C164.022 55.257 164.167 55.3423 164.303 55.4447C164.431 55.547 164.542 55.675 164.619 55.82C164.696 55.965 164.738 56.1441 164.738 56.3488C164.738 56.6133 164.687 56.8436 164.576 57.0227C164.465 57.2104 164.329 57.3554 164.15 57.4748C163.979 57.5857 163.783 57.671 163.57 57.7222C163.356 57.7733 163.135 57.7989 162.921 57.7989C162.572 57.7989 162.23 57.7392 161.898 57.6283C161.565 57.5174 161.292 57.3554 161.07 57.1336L161.855 56.3062C161.983 56.4427 162.137 56.5536 162.316 56.6474C162.495 56.7412 162.691 56.7839 162.896 56.7839C163.007 56.7839 163.117 56.7583 163.228 56.6986C163.339 56.6474 163.399 56.5536 163.399 56.4171C163.399 56.2891 163.331 56.1868 163.194 56.1271C163.058 56.0674 162.845 55.9991 162.555 55.9309C162.41 55.8968 162.256 55.8541 162.111 55.7944C161.966 55.7347 161.829 55.6579 161.719 55.5641C161.608 55.4703 161.505 55.3508 161.437 55.2058C161.36 55.0608 161.326 54.8902 161.326 54.694C161.326 54.4381 161.377 54.2164 161.488 54.0372C161.599 53.8581 161.736 53.7131 161.906 53.6022C162.077 53.4913 162.264 53.406 162.478 53.3548C162.691 53.3037 162.896 53.2781 163.092 53.2781C163.408 53.2781 163.723 53.3293 164.03 53.4231C164.337 53.5254 164.593 53.6705 164.798 53.8666L164.022 54.6684Z" fill="black" />
          <path d="M165.489 53.9946C165.736 53.7558 166.026 53.5766 166.359 53.4572C166.692 53.3378 167.033 53.2781 167.382 53.2781C167.741 53.2781 168.048 53.3207 168.295 53.406C168.543 53.4913 168.739 53.6278 168.892 53.8155C169.046 54.0031 169.157 54.2334 169.233 54.5149C169.302 54.7964 169.336 55.1291 169.336 55.5129V57.6539H168.056V57.2018H168.031C167.92 57.381 167.758 57.5174 167.536 57.6113C167.314 57.7051 167.084 57.7563 166.828 57.7563C166.657 57.7563 166.478 57.7307 166.299 57.688C166.12 57.6454 165.949 57.5686 165.804 57.4663C165.659 57.3639 165.531 57.2274 165.438 57.0568C165.344 56.8862 165.293 56.673 165.293 56.4256C165.293 56.1185 165.378 55.8712 165.54 55.6835C165.711 55.4959 165.924 55.3508 166.188 55.2485C166.453 55.1461 166.751 55.0779 167.075 55.0438C167.399 55.0096 167.715 54.9926 168.022 54.9926V54.9329C168.022 54.7196 167.945 54.5661 167.8 54.4723C167.655 54.3699 167.468 54.3273 167.254 54.3273C167.058 54.3273 166.862 54.3699 166.683 54.4552C166.495 54.5405 166.342 54.6429 166.214 54.7623L165.489 53.9946ZM168.056 55.8115H167.877C167.724 55.8115 167.57 55.82 167.417 55.837C167.263 55.8541 167.118 55.8797 166.999 55.9138C166.879 55.9479 166.777 56.0162 166.7 56.0929C166.623 56.1697 166.581 56.2721 166.581 56.3915C166.581 56.4683 166.598 56.5365 166.64 56.5962C166.674 56.6559 166.726 56.6986 166.785 56.7327C166.845 56.7668 166.905 56.7924 166.982 56.8009C167.058 56.8095 167.127 56.818 167.195 56.818C167.476 56.818 167.698 56.7412 167.852 56.5877C168.005 56.4341 168.073 56.2209 168.073 55.9479V55.8115H168.056Z" fill="black" />
          <path d="M173.132 54.5661C173.072 54.5491 173.004 54.5405 172.952 54.532C172.893 54.5235 172.833 54.5235 172.773 54.5235C172.586 54.5235 172.424 54.5576 172.296 54.6258C172.168 54.6941 172.065 54.7794 171.989 54.8732C171.912 54.967 171.852 55.0779 171.818 55.1888C171.784 55.2997 171.767 55.4021 171.767 55.4874V57.6625H170.368V53.4231H171.716V54.0373H171.733C171.844 53.824 171.997 53.6449 172.193 53.5084C172.39 53.3719 172.62 53.3037 172.884 53.3037C172.944 53.3037 172.995 53.3037 173.055 53.3122C173.106 53.3208 173.157 53.3293 173.191 53.3378L173.132 54.5661Z" fill="black" />
          <path d="M177.405 52.8687V54.114H179.742V55.3167H177.405V57.6625H175.964V51.6233H179.939V52.8687H177.405Z" fill="black" />
          <path d="M183.197 54.5661C183.137 54.5491 183.069 54.5405 183.018 54.532C182.958 54.5235 182.898 54.5235 182.839 54.5235C182.651 54.5235 182.489 54.5576 182.361 54.6258C182.233 54.6941 182.131 54.7794 182.054 54.8732C181.977 54.967 181.917 55.0779 181.883 55.1888C181.849 55.2997 181.832 55.4021 181.832 55.4874V57.6625H180.433V53.4231H181.781V54.0373H181.798C181.909 53.824 182.062 53.6449 182.259 53.5084C182.455 53.3719 182.685 53.3037 182.95 53.3037C183.009 53.3037 183.06 53.3037 183.12 53.3122C183.171 53.3208 183.223 53.3293 183.257 53.3378L183.197 54.5661Z" fill="black" />
          <path d="M183.623 53.9946C183.871 53.7558 184.161 53.5766 184.493 53.4572C184.826 53.3378 185.167 53.2781 185.517 53.2781C185.875 53.2781 186.182 53.3207 186.43 53.406C186.677 53.4913 186.873 53.6278 187.027 53.8155C187.18 54.0031 187.291 54.2334 187.368 54.5149C187.436 54.7964 187.47 55.1291 187.47 55.5129V57.6539H186.191V57.2018H186.165C186.054 57.381 185.892 57.5174 185.671 57.6113C185.449 57.7051 185.218 57.7563 184.963 57.7563C184.792 57.7563 184.613 57.7307 184.434 57.688C184.255 57.6454 184.084 57.5686 183.939 57.4663C183.794 57.3639 183.666 57.2274 183.572 57.0568C183.478 56.8862 183.427 56.673 183.427 56.4256C183.427 56.1185 183.513 55.8712 183.675 55.6835C183.845 55.4959 184.058 55.3508 184.323 55.2485C184.587 55.1461 184.886 55.0779 185.21 55.0438C185.534 55.0096 185.85 54.9926 186.157 54.9926V54.9329C186.157 54.7196 186.08 54.5661 185.935 54.4723C185.79 54.3699 185.602 54.3273 185.389 54.3273C185.193 54.3273 184.997 54.3699 184.818 54.4552C184.63 54.5405 184.476 54.6429 184.348 54.7623L183.623 53.9946ZM186.191 55.8115H186.012C185.858 55.8115 185.705 55.82 185.551 55.837C185.398 55.8541 185.253 55.8797 185.133 55.9138C185.014 55.9479 184.911 56.0162 184.835 56.0929C184.758 56.1697 184.715 56.2721 184.715 56.3915C184.715 56.4683 184.732 56.5365 184.775 56.5962C184.809 56.6559 184.86 56.6986 184.92 56.7327C184.98 56.7668 185.039 56.7924 185.116 56.8009C185.193 56.8095 185.261 56.818 185.329 56.818C185.611 56.818 185.833 56.7412 185.986 56.5877C186.14 56.4341 186.208 56.2209 186.208 55.9479V55.8115H186.191Z" fill="black" />
          <path d="M191.3 57.6625V55.3423C191.3 55.2229 191.292 55.112 191.266 55.0012C191.249 54.8903 191.215 54.7964 191.164 54.7197C191.121 54.6344 191.053 54.5747 190.976 54.5235C190.899 54.4723 190.789 54.4552 190.661 54.4552C190.533 54.4552 190.422 54.4808 190.328 54.5235C190.234 54.5747 190.157 54.6344 190.089 54.7197C190.029 54.805 189.978 54.8988 189.944 55.0097C189.91 55.1206 189.901 55.24 189.901 55.3594V57.6625H188.511V53.4146H189.867V54.0032H189.884C189.936 53.9093 190.004 53.824 190.089 53.7302C190.174 53.6449 190.268 53.5681 190.379 53.5084C190.49 53.4487 190.609 53.3975 190.746 53.3549C190.874 53.3122 191.019 53.2952 191.164 53.2952C191.445 53.2952 191.693 53.3463 191.889 53.4487C192.085 53.5511 192.247 53.6875 192.375 53.8496C192.495 54.0117 192.588 54.1993 192.64 54.4041C192.691 54.6173 192.716 54.822 192.716 55.0353V57.6625H191.3Z" fill="black" />
          <path d="M196.7 54.7879C196.623 54.6855 196.512 54.6002 196.376 54.5405C196.239 54.4808 196.103 54.4467 195.958 54.4467C195.813 54.4467 195.676 54.4723 195.557 54.532C195.437 54.5917 195.335 54.6685 195.25 54.7708C195.164 54.8732 195.096 54.9841 195.045 55.112C194.994 55.24 194.977 55.385 194.977 55.53C194.977 55.675 195.002 55.82 195.045 55.9479C195.088 56.0759 195.156 56.1953 195.25 56.2977C195.335 56.4 195.446 56.4768 195.565 56.528C195.685 56.5877 195.821 56.6133 195.983 56.6133C196.128 56.6133 196.265 56.5877 196.401 56.528C196.538 56.4768 196.649 56.4 196.742 56.2977L197.519 57.2445C197.339 57.4151 197.118 57.5516 196.836 57.6454C196.555 57.7392 196.265 57.7904 195.949 57.7904C195.616 57.7904 195.301 57.7392 195.011 57.6369C194.721 57.5345 194.465 57.381 194.252 57.1848C194.038 56.9886 193.868 56.7497 193.748 56.4768C193.629 56.1953 193.561 55.8797 193.561 55.53C193.561 55.1802 193.62 54.8732 193.748 54.5917C193.868 54.3102 194.038 54.0714 194.252 53.8837C194.465 53.6875 194.721 53.534 195.011 53.4316C195.301 53.3293 195.608 53.2781 195.941 53.2781C196.094 53.2781 196.248 53.2951 196.401 53.3207C196.555 53.3463 196.7 53.389 196.836 53.4316C196.973 53.4828 197.101 53.5425 197.22 53.6107C197.339 53.679 197.433 53.7558 197.527 53.8496L196.7 54.7879Z" fill="black" />
          <path d="M200.854 57.6625V55.3594C200.854 55.24 200.845 55.1291 200.828 55.0182C200.811 54.9073 200.777 54.8135 200.734 54.7367C200.692 54.6599 200.624 54.5917 200.538 54.5405C200.461 54.4894 200.359 54.4723 200.231 54.4723C199.984 54.4723 199.796 54.5576 199.668 54.7282C199.54 54.8988 199.472 55.1206 199.472 55.3679V57.6625H198.073V51.2139H199.472V53.952H199.489C199.583 53.7814 199.736 53.6278 199.95 53.4914C200.163 53.3549 200.41 53.2866 200.709 53.2866C200.999 53.2866 201.238 53.3378 201.442 53.4402C201.639 53.5425 201.801 53.679 201.92 53.8411C202.039 54.0031 202.133 54.1908 202.184 54.3955C202.236 54.6088 202.27 54.8135 202.27 55.0267V57.6539H200.854V57.6625Z" fill="black" />
          <path d="M204.837 52.0582C204.837 52.1691 204.812 52.2715 204.769 52.3653C204.726 52.4591 204.667 52.5444 204.598 52.6127C204.53 52.6809 204.445 52.7321 204.343 52.7747C204.24 52.8174 204.138 52.8345 204.035 52.8345C203.805 52.8345 203.618 52.7577 203.464 52.6127C203.31 52.4591 203.234 52.28 203.234 52.0668C203.234 51.9644 203.251 51.8706 203.293 51.7767C203.336 51.6829 203.387 51.5976 203.464 51.5294C203.541 51.4611 203.626 51.41 203.72 51.3673C203.814 51.3247 203.925 51.2991 204.035 51.2991C204.146 51.2991 204.249 51.3161 204.343 51.3588C204.445 51.4014 204.53 51.4526 204.598 51.5209C204.667 51.5891 204.726 51.6659 204.769 51.7682C204.812 51.845 204.837 51.9473 204.837 52.0582ZM203.336 57.6624V53.4145H204.735V57.6624H203.336Z" fill="black" />
          <path d="M208.343 54.6684C208.232 54.5576 208.096 54.4723 207.942 54.3955C207.789 54.3187 207.627 54.2846 207.456 54.2846C207.328 54.2846 207.209 54.3102 207.098 54.3614C206.987 54.4126 206.927 54.4979 206.927 54.6258C206.927 54.7452 206.987 54.8305 207.106 54.8817C207.226 54.9329 207.43 54.9926 207.703 55.0523C207.865 55.0864 208.027 55.1376 208.181 55.1973C208.343 55.257 208.488 55.3423 208.625 55.4447C208.753 55.547 208.863 55.675 208.94 55.82C209.017 55.965 209.06 56.1441 209.06 56.3488C209.06 56.6133 209.008 56.8436 208.898 57.0227C208.787 57.2104 208.65 57.3554 208.471 57.4748C208.3 57.5857 208.104 57.671 207.891 57.7222C207.678 57.7733 207.456 57.7989 207.243 57.7989C206.893 57.7989 206.552 57.7392 206.219 57.6283C205.886 57.5174 205.614 57.3554 205.392 57.1336L206.176 56.3062C206.304 56.4427 206.458 56.5536 206.637 56.6474C206.816 56.7412 207.012 56.7839 207.217 56.7839C207.328 56.7839 207.439 56.7583 207.55 56.6986C207.661 56.6474 207.72 56.5536 207.72 56.4171C207.72 56.2891 207.652 56.1868 207.516 56.1271C207.379 56.0674 207.166 55.9991 206.876 55.9309C206.731 55.8968 206.577 55.8541 206.432 55.7944C206.287 55.7347 206.151 55.6579 206.04 55.5641C205.929 55.4703 205.827 55.3508 205.759 55.2058C205.682 55.0608 205.648 54.8902 205.648 54.694C205.648 54.4381 205.699 54.2164 205.81 54.0372C205.921 53.8581 206.057 53.7131 206.228 53.6022C206.398 53.4913 206.586 53.406 206.799 53.3548C207.012 53.3037 207.217 53.2781 207.413 53.2781C207.729 53.2781 208.045 53.3293 208.352 53.4231C208.659 53.5254 208.915 53.6705 209.119 53.8666L208.343 54.6684Z" fill="black" />
          <path d="M214.084 55.5897C214.084 55.6494 214.084 55.7006 214.084 55.7603C214.084 55.82 214.084 55.8712 214.075 55.9223H210.996C211.004 56.0418 211.039 56.1527 211.098 56.2465C211.158 56.3403 211.235 56.4256 211.329 56.4939C211.422 56.5621 211.525 56.6218 211.644 56.6645C211.764 56.7071 211.883 56.7242 212.011 56.7242C212.233 56.7242 212.42 56.6815 212.574 56.5962C212.727 56.5109 212.855 56.4086 212.949 56.2721L213.922 56.8862C213.725 57.1762 213.461 57.398 213.137 57.5601C212.813 57.7136 212.429 57.7989 212.002 57.7989C211.687 57.7989 211.38 57.7477 211.098 57.6539C210.817 57.5516 210.569 57.4066 210.348 57.2189C210.134 57.0312 209.964 56.7924 209.844 56.5194C209.725 56.238 209.657 55.9223 209.657 55.5641C209.657 55.2144 209.716 54.8988 209.836 54.6173C209.955 54.3358 210.117 54.0969 210.322 53.9008C210.527 53.7046 210.766 53.551 211.047 53.4401C211.329 53.3293 211.627 53.2781 211.951 53.2781C212.267 53.2781 212.548 53.3293 212.813 53.4316C213.077 53.534 213.299 53.6875 213.487 53.8922C213.674 54.0969 213.819 54.3358 213.922 54.6173C214.033 54.8988 214.084 55.2229 214.084 55.5897ZM212.796 55.0523C212.796 54.8305 212.727 54.6429 212.582 54.4808C212.446 54.3187 212.233 54.242 211.951 54.242C211.815 54.242 211.687 54.259 211.576 54.3102C211.465 54.3528 211.363 54.4126 211.277 54.4808C211.192 54.5576 211.124 54.6429 211.073 54.7452C211.021 54.8476 210.996 54.9499 210.987 55.0608H212.796V55.0523Z" fill="black" />
          <path d="M219.168 55.5897C219.168 55.6494 219.168 55.7006 219.168 55.7603C219.168 55.82 219.168 55.8712 219.159 55.9223H216.08C216.088 56.0418 216.122 56.1527 216.182 56.2465C216.242 56.3403 216.319 56.4256 216.412 56.4939C216.506 56.5621 216.609 56.6218 216.728 56.6645C216.847 56.7071 216.967 56.7242 217.095 56.7242C217.317 56.7242 217.504 56.6815 217.658 56.5962C217.811 56.5109 217.939 56.4086 218.033 56.2721L219.005 56.8862C218.809 57.1762 218.545 57.398 218.221 57.5601C217.897 57.7136 217.513 57.7989 217.086 57.7989C216.771 57.7989 216.464 57.7477 216.182 57.6539C215.901 57.5516 215.653 57.4066 215.431 57.2189C215.218 57.0312 215.048 56.7924 214.928 56.5194C214.809 56.238 214.741 55.9223 214.741 55.5641C214.741 55.2144 214.8 54.8988 214.92 54.6173C215.039 54.3358 215.201 54.0969 215.406 53.9008C215.611 53.7046 215.849 53.551 216.131 53.4401C216.412 53.3293 216.711 53.2781 217.035 53.2781C217.351 53.2781 217.632 53.3293 217.897 53.4316C218.161 53.534 218.383 53.6875 218.57 53.8922C218.758 54.0969 218.903 54.3358 219.005 54.6173C219.116 54.8988 219.168 55.2229 219.168 55.5897ZM217.88 55.0523C217.88 54.8305 217.811 54.6429 217.666 54.4808C217.53 54.3187 217.317 54.242 217.035 54.242C216.899 54.242 216.771 54.259 216.66 54.3102C216.549 54.3528 216.447 54.4126 216.361 54.4808C216.276 54.5576 216.208 54.6429 216.157 54.7452C216.105 54.8476 216.08 54.9499 216.071 55.0608H217.88V55.0523Z" fill="black" />
          <path d="M222.537 54.6684C222.426 54.5576 222.29 54.4723 222.136 54.3955C221.982 54.3187 221.82 54.2846 221.65 54.2846C221.522 54.2846 221.402 54.3102 221.291 54.3614C221.181 54.4126 221.121 54.4979 221.121 54.6258C221.121 54.7452 221.181 54.8305 221.3 54.8817C221.419 54.9329 221.624 54.9926 221.897 55.0523C222.059 55.0864 222.221 55.1376 222.375 55.1973C222.537 55.257 222.682 55.3423 222.818 55.4447C222.946 55.547 223.057 55.675 223.134 55.82C223.211 55.965 223.253 56.1441 223.253 56.3488C223.253 56.6133 223.202 56.8436 223.091 57.0227C222.98 57.2104 222.844 57.3554 222.665 57.4748C222.494 57.5857 222.298 57.671 222.085 57.7222C221.872 57.7733 221.65 57.7989 221.437 57.7989C221.087 57.7989 220.746 57.7392 220.413 57.6283C220.08 57.5174 219.807 57.3554 219.586 57.1336L220.37 56.3062C220.498 56.4427 220.652 56.5536 220.831 56.6474C221.01 56.7412 221.206 56.7839 221.411 56.7839C221.522 56.7839 221.633 56.7583 221.744 56.6986C221.854 56.6474 221.914 56.5536 221.914 56.4171C221.914 56.2891 221.846 56.1868 221.709 56.1271C221.573 56.0674 221.36 55.9991 221.07 55.9309C220.925 55.8968 220.771 55.8541 220.626 55.7944C220.481 55.7347 220.345 55.6579 220.234 55.5641C220.123 55.4703 220.021 55.3508 219.952 55.2058C219.876 55.0608 219.841 54.8902 219.841 54.694C219.841 54.4381 219.893 54.2164 220.003 54.0372C220.114 53.8581 220.251 53.7131 220.421 53.6022C220.592 53.4913 220.78 53.406 220.993 53.3548C221.206 53.3037 221.411 53.2781 221.607 53.2781C221.923 53.2781 222.238 53.3293 222.545 53.4231C222.852 53.5254 223.108 53.6705 223.313 53.8666L222.537 54.6684Z" fill="black" />
          <path d="M182.455 45.9424C182.131 45.9424 181.815 45.823 181.576 45.5926C181.244 45.277 181.107 44.8079 181.209 44.3643L191.633 0.981214C191.795 0.298821 192.486 -0.127674 193.168 0.0343939C193.612 0.136753 204.07 2.71279 212.634 11.8739C221.181 21.018 223.569 32.158 223.663 32.6272C223.799 33.2755 223.407 33.9237 222.776 34.1114L182.813 45.8912C182.694 45.9253 182.574 45.9424 182.455 45.9424ZM193.808 2.88338L184.212 42.8119L220.874 32.0045C220.038 28.9934 217.274 20.5744 210.766 13.614C204.402 6.80714 196.742 3.83873 193.808 2.88338Z" fill="black" />
          <path d="M212.685 26.571C212.216 26.571 211.764 26.3151 211.542 25.8715C204.726 12.4113 192.239 9.7244 192.119 9.70734C191.42 9.59646 190.942 8.94818 191.044 8.24873C191.147 7.54928 191.804 7.0716 192.503 7.17396C192.639 7.19102 206.321 9.90353 213.819 24.7115C214.143 25.3427 213.888 26.1104 213.256 26.4345C213.077 26.5283 212.881 26.571 212.685 26.571Z" fill="black" />
          <path d="M203.08 32.4652C201.596 32.4652 200.197 31.8852 199.139 30.836C196.964 28.6609 196.964 25.1295 199.139 22.9543C201.314 20.7792 204.846 20.7792 207.021 22.9543C209.196 25.1295 209.196 28.6609 207.021 30.836C205.972 31.8852 204.573 32.4652 203.08 32.4652ZM203.08 23.8841C202.278 23.8841 201.519 24.1997 200.948 24.7627C200.376 25.3257 200.069 26.0848 200.069 26.8952C200.069 27.697 200.385 28.4561 200.948 29.0276C202.082 30.1621 204.07 30.1621 205.204 29.0276C205.776 28.4561 206.083 27.7055 206.083 26.8952C206.083 26.0934 205.767 25.3342 205.204 24.7627C204.641 24.1912 203.89 23.8841 203.08 23.8841Z" fill="black" />
          <path d="M194.064 46.2923C196.434 46.2923 198.355 44.3713 198.355 42.0017C198.355 39.6321 196.434 37.7112 194.064 37.7112C191.694 37.7112 189.773 39.6321 189.773 42.0017C189.773 44.3713 191.694 46.2923 194.064 46.2923Z" fill="white" />
          <path d="M194.064 47.5717C192.58 47.5717 191.181 46.9917 190.123 45.9425C189.074 44.8933 188.494 43.4944 188.494 42.0017C188.494 40.5175 189.074 39.1186 190.123 38.0609C191.172 37.0117 192.571 36.4316 194.064 36.4316C195.557 36.4316 196.947 37.0117 198.005 38.0609C199.054 39.11 199.634 40.5089 199.634 42.0017C199.634 43.4859 199.054 44.8848 198.005 45.9425C196.956 47.0002 195.548 47.5717 194.064 47.5717ZM194.064 38.9906C193.296 38.9906 192.52 39.2806 191.932 39.8692C190.754 41.0463 190.754 42.957 191.932 44.1256C193.109 45.2942 195.019 45.3027 196.188 44.1256C197.357 42.9485 197.365 41.0378 196.188 39.8692C195.599 39.2806 194.832 38.9906 194.064 38.9906Z" fill="black" />
          <path d="M186.745 32.8319C185.321 32.8319 183.888 32.286 182.805 31.2027C180.629 29.0276 180.629 25.4962 182.805 23.321C184.98 21.1459 188.511 21.1459 190.686 23.321C191.735 24.3702 192.315 25.7691 192.315 27.2619C192.315 28.7461 191.735 30.145 190.686 31.2027C189.594 32.286 188.17 32.8319 186.745 32.8319ZM186.745 24.2508C185.944 24.2508 185.184 24.5664 184.613 25.1294C184.041 25.6924 183.734 26.4515 183.734 27.2619C183.734 28.0637 184.05 28.8228 184.613 29.3943C185.747 30.5288 187.735 30.5288 188.869 29.3943C189.441 28.8228 189.748 28.0722 189.748 27.2619C189.748 26.46 189.432 25.7009 188.869 25.1294C188.306 24.5579 187.547 24.2508 186.745 24.2508Z" fill="black" />
        </svg>
      </Link>
      <div>
        <Nav />
      </div>
    </Wrapper>
  </header>
);

export default Header;

import styled from 'styled-components';

const FooterStyles = styled.footer`
  background: ${props => props.theme.gray};

  .Footer__container {
    align-items: flex-start;
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 50px 140px;

    @media (max-width: 1300px) {
      padding: 50px 0;
      margin-left: 20px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      margin: 0;
      padding: 50px 30px;
    }
  }

  .FooterNav__container {
    display: flex;
    flex-direction: row;

    h3 {
      border-bottom: 2px solid #CBCBCB;
      color: ${props => props.theme.darkGray};
      flex-grow: 1;
      font-size: 13px;
      font-weight: bold;
      line-height: 200%;
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }

    ul {
      list-style: none;
      margin: 0; 
      padding: 0;
    }

    li {
      border-bottom: 2px solid #CBCBCB;
      font-size: 13px;
      font-weight: 600;
      line-height: 200%;
      margin-bottom: 6px;
    }
  }

  .Join__container {
    align-items: flex-start;
    display: flex; 
    flex-direction: column;
    text-align: left;

    p {
      color: ${props => props.theme.black};
      font-size: 25px;
      font-weight: 800;
      line-height: 34px;
      width: 279px;
      padding-bottom: 20px;
      text-align: left;
    }

    @media (max-width: 768px) {
      padding-bottom: 30px;
    }
  }

  .FooterNav__group {
    margin-bottom: 0; 
    margin-right: 75px;
    width: 156px;

    @media (max-width: 1300px) {
      margin-right: 15px;
    }

    @media (max-width: 768px) {
      margin-right: 0;
      width: 100%;
    }
  }

  .FooterNav__group--middle {
    @media (max-width: 768px) {
      margin: 50px 0;
    }
  }

  .JoinNow__button {
    background: ${props => props.theme.orange};
    border-radius: 3px;
    border: 3px solid ${props => props.theme.orange};
    box-sizing: border-box;
    color: ${props => props.theme.white};
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    padding: 13px 44px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;

    &:hover, &:active {
      background: transparent;
      color: ${props => props.theme.orange};
    }
  }

  .Copy__container {
    background: ${props => props.theme.midGray};
    font-size: 12px;
    padding: 15px;
    text-align: left;

    p {
      color: ${props => props.theme.white};
    }

    @media (max-width: 768px) {
      padding: 30px;
    }
  }
`;

export default FooterStyles;
